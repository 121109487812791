/**
 * Remote Router Runtime Plugin for Module Federation
 * -------------------------------------------------
 * This plugin extends the capabilities of the module federation runtime by customizing the behavior of the application at runtime.
 *
 * Functionality:
 * 1. The plugin provides several hooks to customize the behavior of the application at runtime.
 * 2. It handles the loading of remote microfrontends and provides a way to handle errors that may occur during this process.
 *
 * How it Works:
 * - The 'errorLoadRemote' method is called when there's an error loading a remote microfrontend. It logs the error and returns a module that displays an error message.
 * - The 'init' method is called when the plugin is initialized. It simply returns the arguments it was called with.
 * - The 'beforeRequest' method is called before a request is made to load a remote microfrontend. It modifies the entry property of the remotes to point to the correct URLs.
 *
 * Usage:
 * - The plugin is a crucial part of the host application as it handles the loading of remote microfrontends.
 *
 * Notes:
 * - This plugin provides a flexible way to control the loading of remote microfrontends, optimizing the loading process and handling errors efficiently.
 *
 * References:
 * - Module Federation discussion
 *   https://github.com/module-federation/universe/discussions/1936
 *
 * - Module Federation runtime documentation
 *   https://github.com/module-federation/universe/tree/main/packages/runtime
 *   https://github.com/module-federation/universe/tree/main/packages/enhanced
 */

window.QIKWEBKIT_URL = window._PREO_DATA && window._PREO_DATA._COMPONENTS_URL;
window.QIKWEBKIT_DEFAULT_URL = 'https://ui-qa.withqikserve.com/';

/*
 * Rewrite default remote entry urls for MFEs based on QikWebKit repositories, e.g:
 * - qikwebkit
 * - qikwebkit-orders
 *
 * The QikWebKit host url is configured as environment url (`QIKWEBKIT_URL`).
 * If not provided, the url configured in Webpack Module Federation plugin will be used.
 */
function getQikWebKitRemoteEntryUrl(remoteEntry) {
  // Append a query param with a unique value to prevent caching
  const timestamp = Date.now();

  if (!window.QIKWEBKIT_URL) {
    return `${remoteEntry}?t=${timestamp}`;
  }

  const remoteHost = window.QIKWEBKIT_URL.replace(/\/$/, '');
  const remoteEntryUrl = new URL(remoteEntry);

  return `${remoteHost}${remoteEntryUrl.pathname}?t=${timestamp}`;
}

export default function webappRemoteRouter() {
  return {
    name: 'webapp-remote-router',
    beforeRequest(args) {
      args.options.remotes.forEach((remote) => {
        remote.entry = getQikWebKitRemoteEntryUrl(remote.entry);
      });

      return args;
    },
  };
}
